<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue';
    import { useLocalStorage } from '@vueuse/core';
    import { debounce } from 'lodash';
		import {appendLanguagePrefixURL} from "../../mixins/appendLanguageURL";

    const searchingWithKeyword = ref(false);
    const keywords = ref('');

    const autocompleteResults = ref([]);
    const autocompleteLoader = ref(true);

    const previousKeywords = useLocalStorage('previousKeywords', []);

    const isOpen = ref(false);
    const searchWrapper = ref(null);
    const handleKeydown = (e) => {
        if (e.key === 'Escape') {
            isOpen.value = false;
        }
    };

    const outsideClickHandler = (e) => {
        if (e.target.classList.contains('nav__search--open') || e.target.classList.contains('nav__search--close')) {
            isOpen.value = false;
        } else if (searchWrapper.value && !searchWrapper.value.contains(e.target)) {
            isOpen.value = false;
        }
    };

    const addTitleToStorage = (autocompleteLink) => {
        const existingKeyword = previousKeywords.value.find(keyword => keyword.title === autocompleteLink.title);
        if (!existingKeyword) {
            previousKeywords.value.push({ title: autocompleteLink.title, slug: autocompleteLink.slug });
            if (previousKeywords.value.length > 3) {
                previousKeywords.value.shift();
            }
        }
    }

    const getSearchResults = async (keywords = 'default', inputClick = false) => {
        if(keywords != 'default' && !inputClick) {
            autocompleteLoader.value = true;
        }
        try {
            if(keywords == 'default' || keywords == '') {
                keywords = 'default';
                searchingWithKeyword.value = false;
            } else {
                searchingWithKeyword.value = true;
            }

            const response = await fetch('/search/' + keywords);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            autocompleteResults.value = await response.json();
            autocompleteLoader.value = false;
        } catch (error) {
            console.error('Failed to search with keywords:', error);
        }
    }

    const debouncedGetSearchResults = debounce(getSearchResults, 300);

    const searchResultsPageUrl = ref(searchResultsPageUrlUrl);

    onMounted(() => {
        document.addEventListener('click', outsideClickHandler);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', outsideClickHandler);
    });
</script>

<template>
    <section class="nav__search" :class="{ 'nav__search--open': isOpen }" ref="searchWrapper" @click="isOpen = true">
        <form :action="searchResultsPageUrl" method="GET">
            <input type="text" placeholder="Waar ben je naar op zoek?" @click="getSearchResults($event.target.value, true)" @keyup="debouncedGetSearchResults($event.target.value)" v-model="keywords" name="keywords" autocomplete="off" /> <!-- TODO: HARDCODED TEXT (BESTAAT IN IRIS SEARCH_PLACEHOLDER)-->
            <button type="submit"><i class="fa-light fa-search"></i></button>
        </form>
        <button class="nav__search--close" @click="isOpen = false"><i class="fa-light fa-xmark"></i></button>
        <div class="nav__search--autocomplete"  :class="{'nav__search--autocomplete-loader': autocompleteLoader}" id="search-autocomplete">
            <div class="nav__search--autocomplete-block" v-if="previousKeywords.length > 0 && !searchingWithKeyword">
                <span class="nav__search--autocomplete-title">Eerder door jou gezocht</span> <!-- TODO: HARDCODED TEXT -->
                <div class="nav__search--autocomplete-items">
                    <a class="nav__search--autocomplete-item" v-for="previousKeyword in previousKeywords.slice().reverse()" :key="previousKeyword" :href="appendLanguagePrefixURL(previousKeyword.slug)">{{ previousKeyword.title }}</a>
                </div>
            </div>

            <div class="nav__search--autocomplete-block" v-if="autocompleteResults.length > 0">
                <span class="nav__search--autocomplete-title" v-if="autocompleteResults.length > 0 && !searchingWithKeyword">Voorgestelde zoektermen</span> <!-- TODO: HARDCODED TEXT -->
                <div class="nav__search--autocomplete-items">
                    <a class="nav__search--autocomplete-item" :class="{'nav__search--autocomplete-item-highlighted' : searchingWithKeyword}" v-for="autocompleteResult in autocompleteResults" :key="autocompleteResult" :href="appendLanguagePrefixURL(autocompleteResult.slug)" @click="addTitleToStorage(autocompleteResult)" v-html="autocompleteResult.title.replace(keywords, `<span>${keywords}</span>`)"></a>
                </div>
            </div>
            <div class="nav__search--autocomplete-block" v-else>
                <span class="nav__search--autocomplete-title">Geen resultaten gevonden</span> <!-- TODO: HARDCODED TEXT -->
            </div>
        </div>
    </section>
</template>


<style lang="scss" scoped>
    @keyframes fa-spin{
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
        }
    }

    .nav__search {
        @apply mx-auto relative z-10;

        &::after {
            @apply content-[""] bg-black fixed inset-0 z-[500] opacity-0 transition-opacity duration-300 pointer-events-none;
            @apply lg:bg-border;
        }

        form {
            @apply w-[606px] relative z-[9999];

            > input {
                @apply w-full h-[52px] pl-5 pr-16 rounded border border-solid border-white transition-colors duration-300;

                &:focus,
                &:focus-within {
                    @apply border-primary outline-none;
                }

                &::placeholder {
                    @apply text-[18px] text-[#262626]/50;
                }
            }

            button {
                @apply absolute inset-y-0 right-0 w-[52px] flex items-center justify-center text-[18px] text-dark transition-colors duration-300;
                @apply hover:text-primary;
            }
        }

        .nav__search--autocomplete {
            @apply absolute inset-x-0 top-full bg-white border border-solid border-border rounded z-[9999] transition-all duration-300 opacity-0 pointer-events-none;
            @apply lg:rounded-none lg:transition-none;

            &::after {
                @apply absolute top-1/2 left-1/2 content-["\e1d4"] font-fontAwesome -mt-3 opacity-0 pointer-events-none transition-opacity duration-300;
                -webkit-animation: fa-spin 2s linear infinite;
                animation: fa-spin 2s linear infinite;
            }

            > * {
                @apply transition-opacity duration-300;
            }

            &-loader {
                &::after {
                    @apply opacity-100;
                }

                > * {
                    @apply opacity-20 pointer-events-none;
                }
            }
        }

        .nav__search--autocomplete-block {
            @apply px-[22px] pt-4 pb-5;

            + .nav__search--autocomplete-block {
                @apply border-t border-solid border-[#EBE9E6] pt-6;
            }
        }

        .nav__search--autocomplete-title {
            @apply text-18_23_14_22 font-bold text-dark;
        }

        .nav__search--autocomplete-items {
            @apply flex flex-col mt-1;
        }

        .nav__search--autocomplete-item {
            @apply w-full text-17_21_14_21 py-2 transition-all duration-300 cursor-pointer;
            @apply hover:text-primary;
        }
    }

    .nav__search--close {
        @apply flex-[0_0_40px] w-10 h-11 text-[14px] items-center justify-center hidden transition-all duration-300 z-[999];
        @apply hover:text-primary;

        i {
            @apply pointer-events-none;
        }
    }

    .nav__search--open {
        .nav__search--autocomplete {
            @apply opacity-100 pointer-events-auto;
        }

        .nav__search--close {
            @apply lg:flex;
        }

        &::after {
            @apply opacity-60 pointer-events-auto;
            @apply lg:opacity-100;
        }
    }
</style>
